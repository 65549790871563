import "@scss/style.scss";

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import { Modal, Offcanvas } from "bootstrap";

import "@js/components/statesMap";

$(function () {
    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
    });
});

$(function () {
    let hash = window.location.hash;
    jumpLink(hash);
});

$("a[href*='#']").on("click", function (e) {
    let url = $(e.currentTarget).attr("href");
    let targetPath = url.slice(0, url.indexOf("#"))

    if (window.location.pathname.startsWith(targetPath)) {
        e.preventDefault();    
        let hash = url.slice(url.indexOf("#"));
        jumpLink(hash);
    }
});

function jumpLink(hash) {
    if ($(hash).length) {
        window.location.hash = hash;
        let top = $(hash).offset().top;
        $(window).scrollTop(top - $("header").outerHeight());
    }
}

$(".row-title").on("click", function (e) {
    let id = $(e.currentTarget).data("row-content");

    $(".row-body")
        .not("#" + id)
        .addClass("d-none");

    $("#" + id).toggleClass("d-none");

    $(".row-title")
        .not("#" + id)
        .find("i")
        .attr("class", "fas fa-angle-down");

    if (!$("#" + id).hasClass("d-none")) {
        $(e.currentTarget).find("i").attr("class", "fas fa-angle-up");
    } else {
        $(e.currentTarget).find("i").attr("class", "fas fa-angle-down");
    }
});

$(".nav-links > .nav-item")
    .after()
    .on("click", (e) => {
        $(".nav-links > .nav-item").not(e.currentTarget).removeClass("active");
        $(e.currentTarget).toggleClass("active");
    });
