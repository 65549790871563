import $ from "jquery";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
var current_width = $(window).width(),
    zoom = 4,
    iniCenter = [3.551, -2.6],
    className = "",
    map_container = "resourcesMap",
    clickedState = null,
    hoverColor = "",
    hoveredState = null;

window.initMap = function initMap(data) {
    hoverColor = data["hoverColor"];
    if (current_width >= 1200) {
        $(".map-wrapper").css("height", "700px");
    } else if (current_width >= 1024 && current_width < 1200) {
        zoom = 3.7; //set map zoom level for desktop size
        iniCenter = [0.551, -4.6];
        $(".map-wrapper").css("height", "700px");
    } else if (current_width >= 768 && current_width < 1024) {
        zoom = 3.2; //set map zoom level for mobile size
        iniCenter = [0.551, -4.6];
        $(".map-wrapper").css("height", "500px");
    } else {
        zoom = 2.2; //set map zoom level for mobile size
        iniCenter = [3.551, -2.6];
        className = "mobilePopUp";
        $(".map-wrapper").css("height", "250px");
        let mobileStatesList = "";
        let index = 0;
        for (const key in data["features"]) {
            if (Object.hasOwnProperty.call(data["features"], key)) {
                let badge = `
                    <div class="col-12 px-0">
                        <div class="legend-circle" data-state="${key}">${key}</div>
                    </div>
                `;

                if (index == 0) {
                    badge = `<div class="col-4"><div class="row">` + badge;
                } else if (index == 17) {
                    badge = badge + "</div></div>";
                }
                
                mobileStatesList = mobileStatesList + badge;
                index = index == 17 ? 0 : index + 1;
            }
        }
        $("#states-circle").html(`
            <div class="container ">
                <div class="row ps-4 mobileStatesList">${mobileStatesList}</div>
            </div>
        `);
    }

    mapboxgl.accessToken =
        "pk.eyJ1IjoidG9iYWNjb2ZyZWVraWRzIiwiYSI6IlQ3bEdmNW8ifQ.aFJ5TdySQqX7x4ecxAp3Ug";

    const map = new mapboxgl.Map({
        container: map_container,
        style: "mapbox://styles/tobaccofreekids/cl09wli9v001x14lc7370njdl",
        center: iniCenter,
        minZoom: zoom,
        scrollZoom: false,
        boxZoom: false,
        doubleClickZoom: false,
    });

    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());
    map.on("load", () => {
        map.resize();
        map.addSource("states", {
            type: "vector",
            url: "mapbox://mbxsolutions.albersusa",
        });

        map.on("mousemove", "counties", (e) => {
            map.getCanvas().style.cursor = "pointer";

            if (e.features.length > 0 && !clickedState) {
                hoveredState = true;
                $(".legend-circle").css("background", "#f0f0f0");

                map.setPaintProperty("counties", "fill-color", [
                    "match",
                    ["get", "state_name"],
                    e.features[0].properties.state_name,
                    hoverColor,
                    "#f0f0f0",
                ]);
            }
        });
        map.on("click", "counties", (e) => {
            if (clickedState) {
                map.setPaintProperty("counties", "fill-color", "#f0f0f0");
                $(".mapboxgl-popup-close-button").click();
                clickedState = null;
            }
            if (e.features.length > 0) {
                clickedState = true;
                $(".legend-circle").css("background", "#f0f0f0");
                showCountryClusterInfo(
                    map,
                    data,
                    e.features[0]["properties"]["state_name"],
                    [e.lngLat.lng, e.lngLat.lat]
                );
                map.setPaintProperty("counties", "fill-color", [
                    "match",
                    ["get", "state_name"],
                    e.features[0].properties.state_name,
                    hoverColor,
                    "#f0f0f0",
                ]);
            }
        });
        // When the mouse leaves the state-fill layer, update the feature state of the
        // previously hovered feature.
        map.on("mouseleave", "counties", () => {
            map.getCanvas().style.cursor = "";
            hoveredState = false;
            if (!clickedState) {
                map.setPaintProperty("counties", "fill-color", "#f0f0f0");
            }
        });
        $(".legend-circle").hover(function (e) {
            if (!clickedState) {
                $(".legend-circle").css("background", "#f0f0f0");
                $(this).css("background", hoverColor);
                hoveredState = true;
                map.setPaintProperty("counties", "fill-color", [
                    "match",
                    ["get", "state_name"],
                    $(this).data("state"),
                    hoverColor,
                    "#f0f0f0",
                ]);
            }
        });
        $(".legend-circle").click(function (e) {
            if (clickedState) {
                map.setPaintProperty("counties", "fill-color", "#f0f0f0");
                $(".legend-circle").css("background", "#f0f0f0");
                $(".mapboxgl-popup-close-button").click();
                clickedState = null;
            }
            clickedState = true;
            showCountryClusterInfo(map, data, $(this).data("state"), [25, 0]);
            $(this).css("background", hoverColor);
            map.setPaintProperty("counties", "fill-color", [
                "match",
                ["get", "state_name"],
                $(this).data("state"),
                hoverColor,
                "#f0f0f0",
            ]);
        });
    });
};

function showCountryClusterInfo(map, jdata, stateName, lngLat) {
    if (stateName in jdata["features"]) {
        let feature = jdata["features"][stateName];

        var stateDescription = feature.properties.comments,
            popup = new mapboxgl.Popup({ className: className })
                .setLngLat(lngLat)
                .setHTML(
                    "<div class='stateDetails'><h3>" +
                        feature.properties.stateName +
                        "</h3><div class='stateDescription'>" +
                        stateDescription +
                        "</div></div>"
                )
                .addTo(map);

        $(".mapboxgl-popup-close-button").click(function (e) {
            map.setPaintProperty("counties", "fill-color", "#f0f0f0");
            $(".legend-circle").css("background", "#f0f0f0");
            clickedState = null;
        });
        if (className) {
            let popUpCoo = $(".mobilePopUp").offset().top;
            $(window).scrollTop(popUpCoo);
        }
    } else {
        map.setPaintProperty("counties", "fill-color", "#f0f0f0");
        $(".legend-circle").css("background", "#f0f0f0");
        $(".mapboxgl-popup-close-button").click();
        clickedState = null;
    }
}
